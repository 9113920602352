import React from "react"

const LProfili = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Proizvodna dužina: 6m, 12m. Po zahtevu moguće je isporučiti veće
          dimenzije i sečenje po meri.
        </p>
      </div>
      <div className="columns">
        <div className="column">
          <table class="table is-bordered is-hoverable">
            <tbody>
              <tr className="has-background-link has-text-white">
                <td className="has-text-centered" rowSpan={2}>
                  <br />
                  <b>Oznaka</b>
                </td>
                <th className="has-text-centered has-text-white" colSpan={3}>
                  Mere (mm)
                </th>
                <th className="has-text-centered has-text-white">Masa</th>
              </tr>
              <tr>
                <td>a</td>
                <td>t</td>
                <td>r</td>
                <td>(kg/m)</td>
              </tr>
              <tr>
                <td>20x20x3</td>
                <td>20</td>
                <td>3</td>
                <td>3,5</td>
                <td>0,882</td>
              </tr>
              <tr>
                <td>25x25x3</td>
                <td>25</td>
                <td>3</td>
                <td>3,5</td>
                <td>1,12</td>
              </tr>
              <tr>
                <td>25x25x4</td>
                <td>25</td>
                <td>4</td>
                <td>2,5</td>
                <td>1,45</td>
              </tr>
              <tr>
                <td>30x30x3</td>
                <td>30</td>
                <td>3</td>
                <td>5</td>
                <td>1,36</td>
              </tr>
              <tr>
                <td>30x30x4</td>
                <td>30</td>
                <td>4</td>
                <td>5</td>
                <td>1,78</td>
              </tr>
              <tr>
                <td>35x35x4</td>
                <td>35</td>
                <td>4</td>
                <td>5</td>
                <td>2,09</td>
              </tr>
              <tr>
                <td>40x40x4</td>
                <td>40</td>
                <td>4</td>
                <td>6</td>
                <td>2,42</td>
              </tr>
              <tr>
                <td>40x40x5</td>
                <td>40</td>
                <td>5</td>
                <td>6</td>
                <td>2,97</td>
              </tr>
              <tr>
                <td>45x45x4,5</td>
                <td>45</td>
                <td>4,5</td>
                <td>7</td>
                <td>3,06</td>
              </tr>
              <tr>
                <td>50x50x4</td>
                <td>50</td>
                <td>4</td>
                <td>7</td>
                <td>3,06</td>
              </tr>
              <tr>
                <td>50x50x5</td>
                <td>50</td>
                <td>5</td>
                <td>7</td>
                <td>3,77</td>
              </tr>
              <tr>
                <td>50x50x6</td>
                <td>60</td>
                <td>6</td>
                <td>7</td>
                <td>4,47</td>
              </tr>
              <tr>
                <td>60x60x5</td>
                <td>60</td>
                <td>5</td>
                <td>8</td>
                <td>4,57</td>
              </tr>
              <tr>
                <td>60x60x6</td>
                <td>60</td>
                <td>6</td>
                <td>8</td>
                <td>5,42</td>
              </tr>
              <tr>
                <td>60x60x8</td>
                <td>60</td>
                <td>8</td>
                <td>8</td>
                <td>7,09</td>
              </tr>
              <tr>
                <td>65x65x7</td>
                <td>65</td>
                <td>7</td>
                <td>9</td>
                <td>6,83</td>
              </tr>
              <tr>
                <td>70x70x6</td>
                <td>70</td>
                <td>6</td>
                <td>9</td>
                <td>6,38</td>
              </tr>
              <tr>
                <td>70x70x7</td>
                <td>70</td>
                <td>7</td>
                <td>9</td>
                <td>7,38</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column">
          <table class="table is-bordered is-hoverable">
            <tbody>
              <tr className="has-background-link has-text-white">
                <td className="has-text-centered" rowSpan={2}>
                  <br />
                  <b>Oznaka</b>
                </td>
                <th className="has-text-centered has-text-white" colSpan={3}>
                  Mere (mm)
                </th>
                <th className="has-text-centered has-text-white">Masa</th>
              </tr>
              <tr>
                <td>a</td>
                <td>t</td>
                <td>r</td>
                <td>(kg/m)</td>
              </tr>
              <tr>
                <td>75x75x6</td>
                <td>75</td>
                <td>6</td>
                <td>9</td>
                <td>6,85</td>
              </tr>
              <tr>
                <td>75x75x8</td>
                <td>75</td>
                <td>8</td>
                <td>9</td>
                <td>8,99</td>
              </tr>
              <tr>
                <td>80x80x8</td>
                <td>80</td>
                <td>8</td>
                <td>10</td>
                <td>9,63</td>
              </tr>
              <tr>
                <td>80x80x10</td>
                <td>80</td>
                <td>10</td>
                <td>10</td>
                <td>11,90</td>
              </tr>
              <tr>
                <td>90x90x8</td>
                <td>90</td>
                <td>8</td>
                <td>11</td>
                <td>10,90</td>
              </tr>
              <tr>
                <td>90x90x9</td>
                <td>90</td>
                <td>9</td>
                <td>11</td>
                <td>12,20</td>
              </tr>
              <tr>
                <td>90x90x10</td>
                <td>90</td>
                <td>10</td>
                <td>11</td>
                <td>13,40</td>
              </tr>
              <tr>
                <td>100x100x10</td>
                <td>100</td>
                <td>10</td>
                <td>12</td>
                <td>15,00</td>
              </tr>
              <tr>
                <td>100x100x12</td>
                <td>100</td>
                <td>12</td>
                <td>12</td>
                <td>17,80</td>
              </tr>
              <tr>
                <td>120x120x10</td>
                <td>120</td>
                <td>10</td>
                <td>13</td>
                <td>18,20</td>
              </tr>
              <tr>
                <td>120x120x12</td>
                <td>120</td>
                <td>12</td>
                <td>13</td>
                <td>21,60</td>
              </tr>
              <tr>
                <td>130x130x12</td>
                <td>130</td>
                <td>12</td>
                <td>14</td>
                <td>23,60</td>
              </tr>
              <tr>
                <td>150x150x12</td>
                <td>150</td>
                <td>12</td>
                <td>16</td>
                <td>27,30</td>
              </tr>
              <tr>
                <td>150x150x15</td>
                <td>150</td>
                <td>15</td>
                <td>16</td>
                <td>33,80</td>
              </tr>
              <tr>
                <td>160x160x15</td>
                <td>160</td>
                <td>15</td>
                <td>17</td>
                <td>36,20</td>
              </tr>
              <tr>
                <td>200x200x18</td>
                <td>200</td>
                <td>18</td>
                <td>18</td>
                <td>54,30</td>
              </tr>
              <tr>
                <td>200x200x20</td>
                <td>200</td>
                <td>20</td>
                <td>18</td>
                <td>59,90</td>
              </tr>
              <tr>
                <td>200x200x24</td>
                <td>200</td>
                <td>24</td>
                <td>18</td>
                <td>71,10</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default LProfili
