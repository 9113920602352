import React from "react"
import ProductsLayout from "../../../components/productsLayout"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import LProfiliTable from "../../../components/tables/LProfili"
import Img1 from "../../../images/lprofil.jpg"
import Img2 from "../../../images/lskica.png"
import Head from "../../../components/head"

const LProfili = () => {
  const title = "Toplovaljani jednokraki ugaonici - L profili"
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
  ]
  return (
    <Layout>
      <Head title={title} />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">{title}</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi/profili-nosači">
                          Profili i Nosači
                        </Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          {title}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>TOPLOOVALJANI JEDNOKRAKI UGAONICI - L PROFILI</strong>
                <br />
                Ugaonici od konstrukcionog čelika sa jednakim i nejednakim
                kracima, mere definisani standardom SRPS EN 10056-1, namenjeni
                su za izradu celicnih konstrukcija i drugih sklopova u
                industriji i gradevinarstvu.
              </p>
              <div
                className="columns is-multiline"
                style={{ paddingTop: "10px" }}
              >
                <div className="column is-full">
                  <p>
                    <strong>Kvalitet čelika</strong>
                    <br />
                  </p>
                  <p>
                    Prema standardu za tehničke zahteve za isporuku SRPS EN
                    10056-1 za toplovaljane proizvode od nelegiranih
                    konstrukcionih čelika, uoblčajeni kvalitet:
                  </p>
                </div>
                <div className="column">
                  <ul>
                    <li>SRPS EN 10027-1</li>
                    <li>S23JR</li>
                    <li>S275JR</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>DIN 17100</li>
                    <li>RSt 37-2</li>
                    <li>RSt 42-2</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>SRPS EN 10027-2</li>
                    <li>1.0037</li>
                    <li>1.0044</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <p>
                    <strong>Dozvoljena odstupanja</strong>
                    <br />
                  </p>
                  <p>
                    Propisuje standard za tolerancije oblika i mera SRPS EN
                    10056-2 za ugaonike od konstrukcionog čelika sa jednakim i
                    nejednakim kracima.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="tab-section">
            <div class="tabs">
              <ul>
                <li className="is-active is-size-3">
                  <a>Asortiman</a>
                </li>
              </ul>
            </div>
            <LProfiliTable />
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default LProfili
